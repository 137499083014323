<template>
  <b-form>
    <b-row>
      <b-col md="12">
        <b-form-group label="Заголовок">
          <b-form-input v-model="form.title" placeholder="Заголовок" :state="formErrors.title"/>
        </b-form-group>
        <b-form-group label="Текст рекламы">
          <b-form-textarea v-model="form.promotion" placeholder="Текст рекламы" :state="formErrors.promotion"></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <b-button variant="primary" @click="voice(form.promotion)" :disabled="voiceLoading">Прослушать</b-button>
        </b-form-group>
        <b-form-group label="Позиция">
          <b-form-input v-model="form.order" placeholder="позиция" :state="formErrors.order"/>
        </b-form-group>
        <b-form-checkbox v-model="form.isActive">
          Активность
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import VoiceSynthesizerApi from "@/api/voiceSynthesizer.api";
import validate from "@/utils/formValidator"
import PROMOTION from "@/forms/PROMOTION"

const PROMOTION_INIT_FORM = () => ({
  title: '',
  promotion: '',
  order: '',
  isActive: false
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => PROMOTION_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      errors: {},
      voiceLoading: false,
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    voice(text) {
      this.voiceLoading = true
      VoiceSynthesizerApi.getVoice(text).then(response => {
        const voice = response.data.voice
        const voiceAudio = new Audio(voice);
        voiceAudio.play().finally(() => {
          this.voiceLoading = false
        })
      })
    },
    fillForm(form) {
        Object.assign(this.form, form)
    },
    resetForm() {
      Object.assign(this.form, PROMOTION_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, PROMOTION)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      this.$emit('onSave', this.form);
    }
  }
}
</script>

<style scoped>
</style>