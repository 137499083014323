<template>
  <div>
    <b-modal
        id="modal-start-trip"
        ref="modalStartTrip"
        title="Начать рейс"
        hide-footer
        size="lg"
    >
      <start ref="startForm" btn-title="Начать" btn-variant="primary" @onSave="startTrip"/>
    </b-modal>
    <b-modal
        id="modal-start-loading"
        ref="modalStartLoading"
        title="Начать посадку"
        hide-footer
        size="lg"
    >
      <div>
        <b-form-group label="Номер платформы">
          <b-form-input v-model="startLoading.platform"/>
        </b-form-group>
        <b-button @click="startLanding(startLoading.tripId)">Начать посадку</b-button>
      </div>
    </b-modal>
    <b-modal
        id="modal-edit"
        ref="modalEdit"
        title="Изменить платформу"
        hide-footer
        size="lg"
    >
      <div>
        <b-form-group label="Номер платформы">
          <b-form-input v-model="startLoading.platform"/>
        </b-form-group>
        <div class="text-left">
          <b-button @click="editLanding()" variant="primary">Сохранить</b-button>
        </div>
      </div>
    </b-modal>
    <h2>Расписания и ТС</h2>
    <div class="form-group text-right">
      <a href="/information-table" class="btn btn-primary" target="_blank">Информационное табло</a>
    </div>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="data" :fields="fields" :small="true">
            <template #cell(route)="data">
              <span><b>{{data.item.route.number}}</b><span style="font-style: italic; margin: 0 6px">{{data.item.carrier.carrierName}}</span><br><p>{{data.item.route.name}}</p></span>
            </template>
            <template #cell(gosNumber)="data">
              <span><b>{{data.item.bus.gosNumber}}</b><br><span v-if="data.item.status === 'landing'">Продано билетов: {{ data.item.tickets.filter(ticket => ticket.status === 'paid').length }}</span></span>
            </template>
            <template #cell(platform)="data">
              <b v-if="data.item.status === 'landing'">{{data.item.platform}}</b>
            </template>
            <template #cell(status)="data">
              <div>
                <div v-if="data.item.status === 'landing'">
                  <span style="padding: 3px 6px; color: #fff; background-color: #5fad49; border-radius: 3px;">ПОСАДКА</span>
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <div v-if="data.item.status === 'planned'">
                <b-button variant="primary" style="margin-left: 10px;" @click="modalStartLoading(data.item.id)">Начать посадку</b-button>
              </div>
              <div v-else-if="data.item.status === 'landing'">
                <b-button variant="success" style="margin-left: 10px; vertical-align: center" @click="openEditModal(data.item)"><b-icon icon="pencil-fill" scale="0.8"></b-icon> Изменить</b-button>
<!--                <b-button variant="info" style="margin-left: 10px;"><b-icon icon="megaphone-fill" font-scale="1p"></b-icon> Посадка</b-button>-->
                <b-button @click="tripToggleStatus(data.item.id)" variant="danger" style="margin-left: 10px;" ><i class="zmdi zmdi-directions-bus action-icon white-icon"></i> В рейс</b-button>
<!--                <b-button variant="danger" style="margin-left: 10px;"><b-icon icon="trash-fill"></b-icon></b-button>-->
              </div>
              <div v-if="data.item.status === 'in_trip'">
                <b-button variant="success" style="margin-left: 10px;" @click="finish(data.item.id)">Прибыл</b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-overlay>
    <PromotionBlock/>
  </div>
</template>

<script>
import TripsApi from "@/api/trips.api";
import moment from "moment";
import Start from '../Flights/WorkWithTrips/Start'
import PromotionBlock from './PromotionBlock.vue'

export default {
  name: "SchedulesAndVehicles",
  data() {
    return {
      startLoading: {
        tripId: null,
        platform: '',
      },
      data: [],
      errors: {},
      loading: false,
      fields: [
        {
          key: 'startTime',
          label: 'Отправление',
          formatter: value => moment(value).format('HH:mm')
        },
        {
          key: 'route',
          label: 'Маршрут'
        },
        {
          key: 'gosNumber',
          label: 'Гос.номер'
        },
        {
          key: 'platform',
          label: 'Платформа'
        },
        {
          key: 'status',
          label: 'Статус'
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ]
    }
  },
  mounted() {
    this.loadTrips();
  },
  methods: {
    openEditModal(tripData) {
      console.log(tripData)
      this.$refs.modalEdit.show()
      this.startLoading.tripId = tripData.id
      this.startLoading.platform = tripData.platform
    },
    editLanding() {
      this.loading = true;
      this.$refs.modalEdit.hide()
      TripsApi.edit({
        tripId: this.startLoading.tripId,
        platform: this.startLoading.platform
      }).then(response => {
        if (response.data.status === 'success') {
          this.$bvToast.toast('Рейс рейс был изменен.', {
            title: 'Успех',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: true
          })
        }
      }).finally(() => {
        this.loadTrips()
      })
    },
    finish(tripId) {
      this.loading = true;
      TripsApi.finish({
        tripId: tripId
      }).then(response => {
        if (response.data.status === 'success') {
          this.$bvToast.toast('Рейс прибыл.', {
            title: 'Успех',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: true
          })
        }
      }).finally(() => {
        this.loadTrips()
      })
    },
    modalStartLoading(tripId) {
      this.$refs.modalStartLoading.show()
      this.startLoading.tripId = tripId
      this.startLoading.platform = ''
    },
    loadTrips() {
      this.loading = true;
      TripsApi.getCurrentTrips().then(response => {
        console.log('data')
        console.log(response.data)
        this.data = response.data
      }).finally(() => {
        this.loading = false;
      })
    },
    tripToggleStatus(tripId) {
      console.log(tripId)
      this.$refs.modalStartTrip.show()
      setTimeout(() => {
        this.$refs.startForm.fillForm({
          tripId
        })
      }, 0);
    },
    startLanding(tripId) {
      if (!this.startLoading.platform) {
        alert('Необходимо указать номер платформы.');
        return;
      }

      this.loading = true;
      this.$refs.modalStartLoading.hide()
      TripsApi.landing({
        tripId: tripId,
        platform: this.startLoading.platform
      }).then(response => {
        if (response.data.status === 'success') {
          this.$bvToast.toast('Посадка начата.', {
            title: 'Успех',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: true
          })
        }
      }).finally(() => {
        this.loadTrips()
      })
    },
    startTrip(form) {
      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Trips/start', form)
          .then(() => {
            this.loadTrips();
            this.$refs.modalStartTrip.hide()
            this.$refs.startForm.resetForm()
            this.$bvToast.toast('Рейс был успешно отправлен.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    },
  },
  components: {
    Start,
    PromotionBlock
  }
}
</script>

<style scoped>

</style>